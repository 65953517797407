import Layout from "components/core/Layout"
import SEO from "components/core/SEO"

const NotFoundPage = () => (
  <Layout bodyClassName="fullheight">
    <SEO title="Not found" disallowRobots={true} />

    <div className="container notice">
      <div>
        <h1>Not found</h1>
        <p className="subhead">
          This page doesn't exist. Contact <a href="mailto:hello@candor.co">hello@candor.co</a> if you believe you
          received this in error.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
